import React, { Component } from 'react';
import { Image, Typography, Watermark } from 'antd';
import { titleStyle } from '../../../css/cssJs';
import './company.css';
import {withRouter} from "react-router-dom";
import request from "../../../util/request";
import {getSession} from "../../../util/sessionUtil";

const { Title} = Typography;

const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px auto',
    maxWidth: '1600px',
};

/*最多显示4行*/
const contentContainerStyle = {
    width: '50%',
    height: '450px',
    padding: '40px 40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

const imageContainerStyle = {
    width: '50%',
    height: '400px',
    background: '#f4f4f5',
    overflow: 'hidden',
    padding: '20px 20px',
};

const textStyle = {
    textIndent: '2em',
    textJustify: 'inter-ideograph',
    textAutospace: 'ideograph-numeric',
    fontFamily: 'sans-serif',
    fontSize: '18px',
    lineHeight: '1.8',
    textAlign: 'justify',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    height: '400px'
};

class Company extends Component {
    initCompanyInfo() {
        return request('/dubai/query/company', {
            method: 'GET',
        });
    }

    componentDidMount() {
        this.setState({
            isEnglish: getSession('language') === 'en',
        })
        this.initCompanyInfo().then((res) => {
            if (res.code === 200) {
                this.setState({
                    companyInfo: res.data,
                });
            }
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            companyInfo: {
                name: 'loading',
                enDescription: 'loading',
                description:
                    'loading',
                fileVOList: [
                    {
                        fileId: 1,
                        url: 'loading'
                    }
                ]
            },
            isEnglish: true,
        };
    }

    render() {
        const { companyInfo } = this.state;

        return (
            <div
                onClick={() =>{
                    this.props.history.push('/about')
                }}
                className='company-card' style={{ marginBottom: '20px', ...containerStyle }}>
                <div style={contentContainerStyle}>
                    <Watermark font={{ fontSize: 40, color: 'rgba(0,0,0,0.15)' }} content={this.state.isEnglish?companyInfo.enName:companyInfo.name}>
                        <Title style={titleStyle} level={2}>
                            { this.state.isEnglish?companyInfo.enName:companyInfo.name}
                        </Title>
                        <div style={textStyle}>{this.state.isEnglish?companyInfo.enDescription:companyInfo.description}</div>
                    </Watermark>
                </div>
                {
                    companyInfo.fileVOList[0].url ?
                        <div style={imageContainerStyle}>
                            <Image preview={false} width={'100%'} height={'100%'} src={companyInfo.fileVOList[0].url} />
                        </div>
                        : null
                }
            </div>
        );
    }
}

export default withRouter(Company);
