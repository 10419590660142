import React, {Component} from 'react';
import Meta from "antd/es/card/Meta";
import Card from "antd/es/card/Card";
import {colorBgProduct} from "../../../css/cssJs";
import {Col, Pagination, Row,Checkbox} from "antd";
import Search from "antd/es/input/Search";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import request from "../../../util/request";
import {getSession} from "../../../util/sessionUtil";

/*产品排布样式：flex布局，横排分布，从左往右*/
const productStyle = {
    backgroundColor: colorBgProduct,
}

const productList = () => {
    let list = []
    for (let i = 0; i < 2; i++) {
        list.push(
            {
                productId: 10001 + i,
                name: 'loading',
                enName: 'loading',
                description: 'loading',
                enDescription: 'loading',
                fileVOList: [
                    {
                        fileId: 1,
                        url: ''
                    }
                ]
            }
        )
    }
    return list
}

class ProductHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productList: productList(),
            searchKey: '',
            current: 1,
            size: 10,
            total: 30,
            isEnglish: true,
            attributeList: [
                {
                    id: 1,
                    name: 'loading',
                    pId: 0,
                    enName: 'loading',
                    children: []
                }
            ],
            selectedAttributeList: [],
            selectedMap:new Map()
        };
        this.getOptions.bind(this)
    }

    search() {
        request({
            url: '/dubai/query/product/list',
            method: 'post',
            data: {
                key: this.state.searchKey,
                nodeIds: this.state.selectedAttributeList,
                current: this.state.current,
                size: this.state.size
            }
        }).then(res => {
            this.setState({
                productList: res.data.records
            })
        })
    }

    initAttribute() {
        return request({
            url: '/dubai/query/attribute/list',
            method: 'post',
        })
    }

    componentDidMount() {
        this.setState({
            isEnglish: getSession('language') === 'en',
        })
        this.initAttribute().then(res => {
            this.setState({
                attributeList: res.data,
                total: res.data.total
            })
        })
        this.search()
    }

    getSelected(index,item){
        this.state.selectedMap.set(index,item)
        // 将map的值复制到list

        new Promise((resolve,reject)=>{
            let list = []
            this.state.selectedMap.forEach((value,key)=>{
                if (value.length !== 0){
                    value.forEach((item,index)=>{
                        list.push(item)
                    })
                }
                this.setState({
                    selectedAttributeList:list
                })
                resolve()
            })
            }
        ).then(()=>{
            this.search()
        })


    }

    getOptions(item){
        let list = []
        // eslint-disable-next-line array-callback-return
        item.children.map((item)=>{
            list.push({
                label:this.state.isEnglish?item.enName:item.name,
                value:item.id
            })
        })
        return list
    }

    render() {
        const {t} = this.props
        return (
            <div>
                {/*商品搜索框*/}
                <Row>
                    <Col md={{span: 12, offset: 6}} xs={{span: 24}}>
                        <Search
                            placeholder={t('product.searchPlaceholder')}
                            onSearch={(value) => {
                                this.setState({
                                    searchKey: value
                                },this.search)
                            }}
                            style={{width: '100%', margin: '20px 0'}}
                        />
                    </Col>
                </Row>
                {/*商品类别筛选：用途*/}
                {
                    this.state.attributeList.map((item, index) => {
                        return (
                            <Row style={{alignItems: 'center'}}>
                                {/*类别名称:用途*/}
                                <Col md={{span: 4, offset: 1}} xs={{span: 6, offset: 1}}>
                                    <h3>{this.state.isEnglish ? item.enName : item.name}</h3>
                                </Col>
                                <Col md={{span: 18}} xs={{span:16}}>
                                    <Checkbox.Group options={this.getOptions(item)}
                                                    onChange={this.getSelected.bind(this,index)}>
                                    </Checkbox.Group>
                                </Col>
                            </Row>
                        )
                    })
                }


                <Row style={productStyle}>
                    {
                        this.state.productList.map((item, index) => {
                            return (
                                <Col xxl={{span: 4}} xl={6} md={8} sm={12} xs={{span: 24}} key={index}>
                                    <Card
                                        key={index}
                                        hoverable
                                        style={{
                                            padding: '20px 20px',
                                            margin: '20px 20px',
                                        }}
                                        onClick={() => {
                                            console.log('点击商品', item)
                                            this.props.history.push({
                                                pathname: '/product/Detail/' + item.productId
                                            })
                                        }}
                                        cover={<img alt="example" src={item.fileVOList[0].url}/>}
                                    >
                                        <Meta title={this.state.isEnglish ? item.enName : item.name}
                                              style={{
                                                  textOverflow: 'ellipsis',
                                                  textAlign: 'center',
                                                  overflow: 'hidden',
                                                  display: '-webkit-box',
                                                  WebkitLineClamp: 1,
                                                  WebkitBoxOrient: 'vertical',
                                              }}
                                        >
                                        </Meta>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
                {/*分页按钮*/}
                <Row style={{
                    textAlign: 'center',
                    padding: '10px 10px',
                }}>
                    <Col md={{span: 12, offset: 6}} xs={{span: 24}}>
                        <Pagination
                            showSizeChanger
                            onChange={(page, pageSize) => {
                                console.log('onChange', page, pageSize)
                                this.setState({
                                    current: page,
                                    size: pageSize
                                })
                                this.search()
                            }}
                            onShowSizeChange={(current, size) => {
                                console.log('onShowSizeChange', current, size)
                                this.setState({
                                    size: size,
                                    current: current
                                })
                                this.search()
                            }}
                            hideOnSinglePage={true}
                            pageSizeOptions={[5, 10, 50, 100]}
                            defaultCurrent={this.state.current}
                            total={this.state.total}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withTranslation()(withRouter(ProductHome));
