import React from 'react';
import {Row, Col, Typography, Button} from 'antd';
import {ClockCircleOutlined, LeftOutlined} from '@ant-design/icons';
import request from "../../../util/request";
import {getSession} from "../../../util/sessionUtil";
import 'quill/dist/quill.snow.css';

const { Title, Text } = Typography;

class NewsDetailPage extends React.Component {
    // Sample data for news
    state = {
        newsId: this.props.match.params.id,
        isEnglish: true,
        news: {
            title: 'loading',
            enTitle: 'loading',
            createTime: 'loading',
            content: '<p>loading</p>',
            enContent: '<p>loading</p>',
        },
    };

    initNewsDetail(){
        request({
            url: `/dubai/query/news/${this.state.newsId}`,
            method: 'post'
        }).then(res=>{
            if(res.code === 200){
                this.setState({
                    news: res.data
                })
            }
        })
    }

    componentDidMount() {
        this.setState({
            isEnglish: getSession('language') === 'en',
        })
        this.initNewsDetail()
    }

    render() {
        const { news,isEnglish } = this.state;

        return (
            <div style={{ maxWidth: '1000px', margin: '20px auto', padding: '20px', backgroundColor: '#f0f2f5',borderRadius: '20px',}}>
                <Button style={{marginLeft: '50px'}}  onClick={() => this.props.history.goBack()}><LeftOutlined />back</Button>
                <Row justify="center">
                    <Col span={24}>
                        <Title level={2} style={{ textAlign: 'center' }}>
                            {isEnglish?news.enTitle:news.title}
                        </Title>
                    </Col>
                </Row>
                <Row justify="center" align="middle">
                    <Col>
                        <ClockCircleOutlined style={{ marginRight: '8px' }} />
                        <Text>{news.createTime}</Text>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={24}>
                        <div className="ql-snow">
                            <div className="ql-editor" dangerouslySetInnerHTML={{ __html: isEnglish?news.enContent:news.content }} />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default NewsDetailPage;
