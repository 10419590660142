import React from 'react';
import {Row, Col, Image} from 'antd';
import { withRouter } from 'react-router-dom';

const NewsBox = ({ id, title, intro, imageUrl, history }) => {
    const handleNewsClick = () => {
        history.push(`/news/detail/${id}`);
    };

    return (
        <div key={id} style={{ marginBottom: 20 }} onClick={handleNewsClick}>
            <Row className='news-card' gutter={16}>
                {/* 左侧，标题和简介 */}
                <Col xs={24} md={{ span: 8, offset: 4 }}>
                    <h3 className='title'>{title ? title : 'loading'}</h3>
                    <div>
            {/*<span>*/}
            {/*  <FieldTimeOutlined style={{ marginRight: 8 }} />*/}
            {/*    {createTime ? createTime : 'createTime is null'}*/}
            {/*</span>*/}
                    </div>
                    <p className='news-description-content'>{intro ? intro : 'loading'}</p>
                </Col>
                {/* 右侧，新闻图片 */}
                <Col xs={24} md={{ span: 10, offset: 1 }}>
                    <Image preview={false} src={imageUrl} alt="loading" style={{ width: '500px', height: '200px' }} />
                </Col>
            </Row>
        </div>
    );
};

export default withRouter(NewsBox);
