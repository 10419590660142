import React, {Component} from 'react';
import Banner   from "./banner/banner";
import {Col, Row} from "antd";
import News from "./news/news";
import {colorBgProduct, titleStyle} from "../../css/cssJs";
import Company from "./company/company";
import Product from "./product/product";


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <div>
                <Row style={{marginBottom: '20px'}}>
                    <Col span={24}>
                        <Banner />
                    </Col>
                </Row>
                {/*集团介绍模块*/}
                <Company/>
                {/*产品展示模块*/}
                <div >
                    <Row style={{background:colorBgProduct
                    }}>
                        <Col span={24}>
                            <h1 style={titleStyle}>
                                Our Product
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Product/>
                        </Col>
                    </Row>
                </div>
                {/*新闻模块*/}
                <Row style={{
                    padding: '2em 2em',
                }}>
                    <Col span={24}>
                        <News/>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default Home;
