import React, {Component} from 'react';
import request from "../../util/request";
import 'quill/dist/quill.snow.css';

class Custom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '',
            enContent: ''
        }
    }


    componentDidMount() {
        // 获取路径
        const path = this.props.location
        console.log('path',path)
        request({
            url: '/dubai/query/menu/content',
            method: 'post',
            params: {
                path: path.pathname
            }
        }).then(res=>{
            console.log('获取自定义菜单成功',res)
            this.setState({
                content: res.data.content,
                enContent: res.data.enContent
            })
        })

    }

    render() {
        return (
            <div
                style={{
                    width: '1000px',
                    margin: '20px auto',
                    padding: '20px',
                    backgroundColor: '#f0f2f5',
                    borderRadius: '20px'
                }}
                className="ql-snow"
            >
                <div className="ql-editor" dangerouslySetInnerHTML={{ __html: this.state.content }} />
            </div>
        );
    }
}

export default Custom;
